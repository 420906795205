@avantPath: "../libs/less";
@bowerPath: "../../../../../vendor/bower_components";

// Import des mixins et variables pour utilisation
@import "@{avantPath}/variables";
@import "@{avantPath}/avant-mixins";
@import "@{bowerPath}/bootstrap/less/mixins";

body, a, button{ text-rendering: optimizeLegibility; -webkit-font-smoothing: antialiased; }

.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 999px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.navbar-brand { background-image: none !important;}

a.navbar-brand {
    display: inline-block;
}

.dataTables_wrapper {
    .row-action {
        width : 100px;
        text-align: center;
    }
    .row-id {
        text-align: center;
        width : 100px;
    }
    .row-execute {
        min-width: 120px;
        .caret, .caret:before, .caret:after {
            font-size: inherit;
        }
    }
    .dataTables_length {
        margin: 10px 0;
    }
    .datatable-loading {
        padding: 8px 5px 0px 10px;
    }
}

div.dropdown-menu {
    padding: 1em;
    white-space: nowrap;

    p:last-child {
        margin-bottom: 0;
    }
}

// Toggle for help in panels
.help-toggle {
    a[data-toggle="collapse"] {
        &:not(.collapsed) i.fa-plus:before { content: "\f068"; }
    }
    + div { margin: 20px 80px; }
}

input.daterange {
    cursor: pointer;
}

.form-control.select2-container {
    padding: 0px;
}

#direct-roles ul, #group-roles ul {
    list-style-type: none;
}

.form-group .toggle {
    padding: 7px 0;
}

label.required {
    strong {
        border-bottom: 1px dotted gray;
    }
    .tooltip .tooltip-arrow { display: none; }
}

.focusedform {
    .verticalcenter {

        .brand {display: block; padding-bottom: 40px; margin: 0 auto; width: 200px;}

        position: absolute;
        top: 0;
        left: 50%;
        margin-top: 50px;  //half the height of the div.
        margin-left: -200px;
    }
}

&[class*="col-"] .ms-container {
    width: 100%;
}


#sidebar li > a i,
#sidebar li.active > a:hover i {
    color: @sidebar-icon-color !important;
}
#sidebar li.active > a i,
#sidebar li.active > a:hover i {
    color: @sidebar-active-color !important;
}

.tab-container.tab-left>.nav-tabs>.delimiter,
.tab-container.tab-right>.nav-tabs>.delimiter {
    height: 30px;
    background-color: transparent !important;
}

.tab-content, .tab-pane {
    background-color: #fff;
}

.chat-users.admin-edit-form li { list-style-type: none; }
.chat-users.admin-edit-form li a:after { color: #fff !important; }
.chat-users.admin-edit-form li.active a {
    color: @brand-primary !important;
    .fa { color: #fff !important; background: @brand-primary; }
}
.chat-users.admin-edit-form li.active a :after {
    color: @brand-primary !important;
}
.chat-users.admin-edit-form li a .fa {
  font-size: 14px;
  top: -1px;
  padding: 5px 0px;
  height: 25px;
  width: 25px;
  text-align: center;
  background: rgba(0, 0, 0, 0.15);
  border-radius: @border-radius-small;
  margin: 5px 3px 5px -3px;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.panel-heading.collapsed + .panel-body {
    display: none;
}

.select2-container .select2-choice .select2-search-choice-close {
    right: 30px;
    top:10px;
}

/**
 * Fix elFinder (couleur du text lors d'un renommage)
 */
.elfinder-cwd-view-icons .elfinder-cwd-file .elfinder-cwd-filename.ui-state-hover input[type="text"] {
    background-color: #fff;
    color: #000;
}

.select2-breadcrumb {
    padding: 0;
    margin: 0;
    background-color: inherit;
}


.admin_geo_area_vats_rate {
    width: 120px;
}

.admin_geo_area_vats_status {
    width: 70px;
    text-align: center;
}

.admin_geo_area_vats_startDate {
    width: 150px;
}

.delete-collection {
    width: 70px;
}
/*
.tt-hint {
    .form-control;
}
*/
[class*=panel-].panel-condensed {
    .panel-body {
        padding: 10px 5px ;
    }
}

[class*=panel-].panel-table {
    .panel-body {
        padding: 0px 0px ;
    }

    .dataTables_info,
    .dataTables_empty, {
        padding-left: 10px;
    }

    .dataTables_length {
        margin-top: 5px;
        padding-left: 5px;
        label { margin-bottom: 0; }
        select { border-width: 0; height: 20px; padding: 0 2px; }
    }
    .dataTables_filter {
        label { margin-bottom: 0; }
        input { border-top-width: 0; border-bottom-width: 0; border-right-width: 0; border-left-color: @border-lighter; }
    }

    table.table-bordered {
        border-left-width: 0;
        border-right-width: 0;
    }

    table.dataTable {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .pagination {
        margin: 0;
        display: block;
        >li>a, >li>span { border-top-width: 0; border-bottom-width: 0;}
        >li.next>a, >li.next>span { border-right-width: 0;}
    }
}

.tab-left .row-border .form-group {
    margin: 0 -15px;
}

.coupon-advantage-container,
.coupon-constraint-container {

    &.coupon-constraint-container { .make-md-column(6); min-height: 240px; max-height: 240px; }

    // margin: 20px 0;
    border-top: 2px solid #666;
    padding-top: 10px;
    margin-top: 20px;

    hr { margin-top: 0px; }

    .actions {
        padding: 8px 6px; background-color: #eeeeee;
        .form-group {
            display: inline-block; padding-top: 0; padding-bottom: 0;
            .checkbox { margin: 0 0 0 20px; }
        }
    }

    .form-group {
        border-top: 0px; padding-top: 0px;
        > label.col-md-3, > .col-md-9 { width: 50%; }
        &.label-block{
            > label, > .col-md-9 { float: none; width: auto; }
        }
        &.display-inline {
            display: block; float: left;
            padding-right: 0px;
            &:not(:first-child) { margin-left: 20px; }
            > label { width: auto; line-height: 34px; margin-bottom: 0px; }
        }
    }
}

#page-content{
    p.help-block {
        strong{ color: #3D3D3D;}
    }
}
